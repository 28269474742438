<template>
  <form ref="form" class="vue-form"  @submit.prevent="sendEmail">
    <v-text-field label="Imię" dense outlined name="user_name" v-model="name"></v-text-field>
    <v-text-field label="Email" class="email" dense outlined  name="user_email" v-model="email" :rules="emailRules"></v-text-field>
    <v-textarea
     name="message"
     v-model="message"
      dense
      label="Twoja wiadomość"
      auto-grow
      outlined
      rows="8"
      row-height="20"
    ></v-textarea>
      <div v-show="success">
    <p class="succes-message" >Dziękujemy za wysłanie wiadomości!</p>
 
      </div>
   
    <input type="submit" value="Wyślij">
  </form>
</template>
<script >

import emailjs from '@emailjs/browser';
export default {
  
  components: {
  },
  data() {
    return {
      YOUR_SERVICE_ID: 'service_gae68xe',
      YOUR_TEMPLATE_ID: 'template_57k3tur',
      YOUR_PUBLIC_KEY: 'XKIdtutjvtKPUWudX',
      CONTACT_FORM: "form",
      name: "",
      email: "",
      message: "",
      success: false,
      submitted: false,
      emailRules: [ 
        (    v) => !!v || 'Wprowadź adres email', 
        (    v) => /.+@.+/.test(v) || 'Wprowadź poprawny adres e-mail' 
      ],
      messageRules: [
        (    v) => !!v || 'Wprowadź tekst wiadomości', 
        (    v) => v.length < 10000 || 'Wiadomośc jest zbyt długa' 
      ]
    }
  },
  methods: {
    sendEmail() {
   
        emailjs.sendForm(this.YOUR_SERVICE_ID, this.YOUR_TEMPLATE_ID, this.$refs.form,
        this.YOUR_PUBLIC_KEY)
        .then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        });

    
      // Reset form field
      this.success = true
      this.name = ''
      this.email = 'nick@domena.pl '
      this.message = ' '
       }
    },


  
}
</script>

<style lang="scss" scoped>
.vue-form {
  .error {
    border: none !important;
    border-color: #e94b35 !important;
    background: transparent !important;
    input {
      background-color: aqua !important;
      
  }
  
}
 .error-message p {
    color: #e94b35 !important;
  }


  input[type="submit"] {
        align-items: center;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 500;
    width: 100%;
    letter-spacing: 0.0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143em;
    text-transform: uppercase;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    background-color: transparent;
    border: 1px solid #1976d2 !important;
    color: #1976d2 !important;
        height: 36px;
    min-width: 64px;
    padding: 0 16px;
  }
 
}

</style>



<template>
  <section
    id="footer-top"
    class="grey lighten-3 grey--text text--darken-4"
  >
    <v-container>
      <v-row>
        <v-col cols="12" md="5" class="py-12"
          ><v-row no-gutters>
            <v-col cols="12">
            </v-col>
            <v-col cols="12">
              <p class="mt-8 text-justify" style="max-width: 400px">
               Skontaktuj się z nami wysyłając wiadomośc na poniższe adresy mailowe, telefonicznie lub użyj formularza kontaktowego.
              </p>
            </v-col>
            <v-col cols="12">
              <v-list two-line class="transparent">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-map-marker </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase">Adres</v-list-item-title>
                    <v-list-item-subtitle
                      >ul. Gdańska 91/93 lok. 35, 90-613 Łódź</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-email </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase black--text">Email</v-list-item-title>
                    
                    <v-list-item-subtitle
                      ><a class='link' href='m&#97;ilt&#111;&#58;&#37;62%69uro&#64;a%69&#115;yne%7&#51;t&#37;&#54;8e%73ia&#46;p&#108;'>&#98;&#105;uro&#64;aisynesth&#101;s&#105;&#97;&#46;p&#108;</a></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-phone </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase">Telefon</v-list-item-title>
                    <v-list-item-subtitle>+48 660 724 261</v-list-item-subtitle>
                   
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </v-list>
              <div class="d-flex justify-center mt-5">
              </div>
            </v-col>
          </v-row></v-col
        >
        <v-col cols="1" class="text-center hidden-sm-and-down col col-2 py-12">
          <v-divider vertical></v-divider>
        </v-col>
        <v-col cols="12" md="5" class="py-12">
          <h3 class="mb-8 text-uppercase">Wyślij wiadomość </h3>
          <footerContactForm />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import footerContactForm from './footerContactForm.vue'
export default {
  components: {
    footerContactForm,
  },
  data() {
    return {
      
    }
  },
}
</script>
<style>
a.link {
  color: rgba(0, 0, 0, 0.6) !important;
  text-decoration: none;
  
}
</style>

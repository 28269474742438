









import Vue from 'vue';
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import FooterTop from './components/FooterTop.vue'


export default Vue.extend({
  name: 'App',
  components:{
Header,
Footer,
FooterTop
  },

  data: () => ({
    //
  }),
});

<template>
  <v-footer
    class="grey lighten-3 grey--text text--darken-4 site-footer"
  >
    <v-container>
      <v-divider class="mb-5"></v-divider>
      <v-row>
        <v-col cols="12" md="7"
          ><div
            class="d-flex flex-wrap justify-md-start justify-center justify-md-none"
          >
            <template v-for="(s, i) in menu">
              <a
                :key="i"
                class="text--secondary pa-1 pa-md-0"
                :href="s.link"
                v-text="s.text"
              />

              <v-responsive
                v-if="i < menu.length - 1"
                :key="`divider-${i}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="18"
              >
                <v-divider vertical />
              </v-responsive>
            </template></div
        ></v-col>
        <v-col class="text--secondary text-center text-md-right">
          {{ new Date().getFullYear() }} ©
          <router-link class="primary--text" to="/">AiSynesthesia</router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        { text: 'O nas', link: '#' },
        { text: 'Projekty', link: '#' },
        { text: 'Aktualności', link: '#' },
      ],
    }
  },
}
</script>

<style>
.site-footer a {
  text-decoration: none;
}
</style>

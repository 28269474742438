<template>
  <section id="hero">
    <v-carousel
      height="calc(100vh - 64px)"
      dark
      cycle
      show-arrows-on-hover
      draggable="true"
      hide-delimiter-background
    >
      <v-carousel-item
        v-for="(carousel, carindex) in carouselsData"
        :key="carindex"
        :src="carousel.src"
        class="gradient-fill"
      >
        <v-container fill-height>
          <div style="max-width: 600px">
            <div class="text-md-h2 mb-3 text-sm-h3 white--text text-h5 font-weight-black">
              {{ carousel.heading.toUpperCase() }}
            </div>
            <p class="mb-5 white--text">{{ carousel.subHeading }}</p>
            <!-- <v-btn :x-large="$vuetify.breakpoint.smAndUp" class="my-3 primary"
              >Get Started</v-btn
            > -->
            <span class="mx-2 my-4"></span>
            <v-btn
            dark
              :x-large="$vuetify.breakpoint.smAndUp"
              text
              class="my-3"
              outlined
              ><v-icon left large color="primary">mdi-play</v-icon>Zobacz więcej</v-btn
            >
          </div>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
import banner1 from '../assets/banner1.jpg'
import banner2 from '../assets/banner2.jpg'
import banner3 from '../assets/banner3.jpg'
import banner4 from '../assets/banner4.jpg'
export default {
  data() {
    return {
      carouselsData: [
        {
          src: banner1,
          heading: ' Rozwiązania AI dla medycyny ',
          subHeading:
            '',
        },
        {
          src: banner2,
          heading: 'Technologie oparte na sztucznej inteligencji',
          subHeading:
            'Sieci neuronowe, uczenie głębokie.',
        },
        {
          src: banner3,
          heading: ' Systemy oparte na sztucznej inteligencji dla rynku medycznego ',
          subHeading:
            '',
        },
      ],
    }
  },
}
</script>

<style>
.gradient-fill .v-responsive__content {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    to right,
    rgba(3, 12, 41, 0.75),
    rgba(5, 11, 31, 0.65)
  );
}
</style>

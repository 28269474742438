







import { Component, Vue } from 'vue-property-decorator';
import Hero from '../components/Hero.vue'
import Intro from '../components/Intro.vue'


@Component({
  components: {
    Hero,
    Intro
  },
})
export default class Home extends Vue {}
